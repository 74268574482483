'use client'

import { CardDescription } from '@repo/ui/components/ui/card'
import StatusMessage from 'components/card/message/status-message'
import { status as StatusCodes } from 'http-status'
import { useTranslations } from 'next-intl'
import Modal from 'components/layout/modal'
import LoginPage from 'app/(auth)/login/client'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'
import { usePathname } from 'next/navigation'

export default function ErrorClientPage({ code }: { code: keyof typeof StatusCodes & number }) {
    const t = useTranslations('status')
    const pathname = usePathname()
    const router = useRouter()

    useEffect(() => {
        if (code !== StatusCodes.UNAUTHORIZED) return

        const { origin, history } = globalThis ?? {}
        if (!(origin && history)) return

        history.replaceState(null, '', new URL('/login', origin))

        return () => history.replaceState(null, '', new URL(pathname, origin))
    }, [code, pathname])

    return (
        <>
            {code === StatusCodes.UNAUTHORIZED && (
                <Modal
                    title='Login'
                    className='flex flex-col items-center justify-items-center'
                    router={null}
                    onOpenChange={open => {
                        if (open) return
                        const { origin, history } = globalThis ?? {}
                        if (!(origin && history)) return

                        history.replaceState(null, '', new URL(pathname, origin))
                    }}
                >
                    <LoginPage router={router} />
                </Modal>
            )}

            <div className='min-h-screen flex justify-center items-start md:items-center p-8'>
                <StatusMessage status={code}>
                    <CardDescription className='text-md'>{t(code.toString())}</CardDescription>
                </StatusMessage>
            </div>
        </>
    )
}
