'use client'

import { Dialog, DialogContent, DialogDescription, DialogOverlay, DialogTitle, DialogTrigger } from '@repo/ui/dialog'
import { cn } from '@repo/ui/lib/utils'
import { useRouter } from 'next/navigation'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'

export function Modal({
    children,
    trigger,
    title,
    desc,
    className,
    router,
    onOpenChange,
}: {
    children: React.ReactNode
    trigger?: React.ReactNode
    title: string
    desc?: string
    className?: string
    router?: ReturnType<typeof useRouter> | null
    onOpenChange?: Parameters<typeof Dialog>[0]['onOpenChange']
}) {
    if (router === undefined) router = useRouter()

    return (
        <Dialog
            defaultOpen={!trigger}
            // open={!trigger}
            onOpenChange={open => {
                onOpenChange?.(open)
                router?.back()
            }}
        >
            {!!trigger && <DialogTrigger>{trigger}</DialogTrigger>}

            <VisuallyHidden>
                <DialogTitle>{title}</DialogTitle>

                <DialogDescription>{desc}</DialogDescription>
            </VisuallyHidden>

            <DialogContent className={cn(className)}>
                <div className='p-1'>{children}</div>
            </DialogContent>
        </Dialog>
    )
}

export default Modal
